.App {
  text-align: center;
  text-shadow: 1px 1px 2px #000000;
  
}



.App-logo {
  max-height: 256px;
  max-width: 90vw;
  pointer-events: none;
  border-radius: 50%;
  border-color: #FAFAFA;
  border-width: 3px;
  border-style: solid;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  position: absolute;
  display: block;
}

code {
  color: #DB4437
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: breathing 5s ease-out infinite normal;
    animation: breathing 5s ease-out infinite normal;
  }

  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
}

.App-header {
  min-height: calc(100vh - 64px);
  min-height: calc(100dvh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FAFAFA;
}

.small {
  font-size: calc(8px + 2vmin);
  margin: 0 25px 25px 15px;
}

.small2 {
  font-size: calc(6px + 2vmin);
  margin: 0 25px 25px 15px;
}

footer {
  height: 64px;
  color: #FAFAFA;
  font-size: calc(6px + 1vmin);
}

footer p {
  margin: 0 25px;
}

.App-log {
position: relative;
height:256px;
display: flex;
align-items: center;
justify-content: center;
padding-top: 25px;
}

.icons {
  height: calc(24px + 3vmin);
  padding: 12px;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.social {
  display: flex;
  align-items: stretch;
  padding-bottom: 20px;
}

a {
  color:#FAFAFA;
}

a:hover {
  color:#DB4437;
}

.social a {
  transition: transform .2s; /* Animation */
}

.social a:hover {
  transform: scale(1.25); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

